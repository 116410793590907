import Table from 'react-bootstrap/Table';
import { useState } from 'react';
import './Dev.css'

function DevelopersTable({ developers, isBlurred }) {

const [blurFromRow, setBlurFromRow] = useState(3);


  return (
    <Table striped bordered hover variant="dark" 
            className='table_dev'
            style={{borderRadius: '10px', overflow: 'hidden'}}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Github</th>
          <th>Company / Institution</th>
          <th>Location</th>
          <th>Bio</th>
          <th>Email</th>
          <th>Website</th>
        </tr>
      </thead>
      <tbody>
        {developers.map((row, index) => (
          <tr key={index} 
              className={index >= blurFromRow && isBlurred ? 'blurred' : ''}>
            <td>{row.name}</td>
            <td>{row.html_url}</td>
            <td>{row.company}</td>
            <td>{row.location}</td>
            <td>{row.bio}</td>
            <td>{row.email}</td>
            <td>{row.website}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default DevelopersTable;