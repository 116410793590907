import axios from 'axios'
import {
    PROJECT_LIST_REQUEST,
    PROJECT_LIST_SUCCESS,
    PROJECT_LIST_FAIL
} from '../constants/projectConstants'


import { API_URL } from '../sharedVariable'

console.log(':INFO: projectActions.js called:');


export const listProjects = (keyword = '') => async (dispatch) => {

    console.log(':INFO: Getting token:');
    const storedToken = localStorage.getItem('token');
    const token = storedToken ? storedToken.replace(/"/g, '') : null;

    console.log('Token:', token);
    console.log('typeof:', typeof token);
    try {
        dispatch({ type: PROJECT_LIST_REQUEST });

        console.log('Fetching token from local storage...');
        // Get the token from wherever it is stored (e.g., local storage, cookies, etc.)

        // Set the authorization header with the token
        const config = {
            headers: {
                'Authorization': `Token ${token}`
            }
        };

        console.log('Making API request to fetch projects...');
        // Make the API request with the configured headers
        const { data } = await axios.get(`${API_URL}api/project/projects/`, config);
        console.log('Received projects data:', data);

        dispatch({
            type: PROJECT_LIST_SUCCESS,
            payload: data
        });
    } catch (error) {
        console.error('Error occurred while fetching projects:', error);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response Data:', error.response.data);
            console.error('Response Status:', error.response.status);
            console.error('Response Headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in Node.js
            console.error('Request:', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error Message:', error.message);
        }
        console.error('Error Config:', error.config);
        dispatch({
            type: PROJECT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};
