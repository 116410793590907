import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import ResearchTable from '../../components/Research_components/research_tbl_comp';
import './ResultsScreen.css';
import { API_URL } from '../../sharedVariable';

import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from '../../components/Loader';
import { useLocation } from 'react-router-dom';

export default function ResultsScreen() {
  const searchResultsState = useSelector(state => state.searchResultList);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchString = searchParams.get('searchString'); // Get the searchString parameter

  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const userLogin = useSelector(state => state.userLogin);
  const { userInfo } = userLogin;

  console.log(":INFO: Hello from ResultsScreen.jsx: searchResultsState.searchResults: ", searchResultsState.searchResults)  

  const handleAddClick = async (rowData) => {
    try {
      let url = `${API_URL}api/project/create_selected_star/`;
      // Perform actions when "+" icon is clicked
      console.log(":INFO: Hello from ResultsScreen.jsx: User clicked on '+' icon:", rowData);

      console.log(":INFO: Hello from ResultsScreen.jsx: Hello from handleAddClick token", userInfo.token)

      const config = {
        headers: {
          Authorization: `Token ${userInfo.token}`
        }
      };

      // Example POST request using fetch
      const response = await axios.post(
        url,
        { "owner": rowData.owner,
          "repository": rowData.repository,
          "description": rowData.description,
          "why": rowData.why
      },
        config
      );

      if (response.status === 201) {
        const data = await response.data;
        console.log(':INFO: Hello fro ResultsScreen.jsx: POST request successful:', data);
  
        // Show the success alert
        setShowSuccessAlert(true);
  
        // Hide the alert after a certain time (e.g., 3 seconds)
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 5000); // 3000 milliseconds = 3 seconds
      } else {
        console.error('POST request failed');
      }
    } catch (error) {
      console.error('Error while making POST request:', error);
    }
  };

  useEffect(() => {
    
    const loading = JSON.parse(localStorage.getItem('search_load')); // Parse the stored loading state
    if (loading !== null) {
      setIsLoading(loading); // Update the loading state
      console.log(":INFO: ===> Not loading anymore")
    }

    if (searchResultsState.searchResults.length !== 0) {
      // Assuming that searchResultsState.searchResults is an array
      setSearchResult(searchResultsState.searchResults);

      searchResultsState.searchResults.forEach(function(res) {
        localStorage.setItem(`res`, true) // What is "res"?
        localStorage.setItem(`res_arr`, JSON.stringify(searchResultsState.searchResults)) // Serialize the array to store
      });

    }
    else if (localStorage.getItem('res')){
    console.log(":INFO: Hello fro ResultsScreen.jsx: localStorage not empty!")

    // Retrieve the stored array and objects
    const storedArray = JSON.parse(localStorage.getItem('res_arr'));
    setSearchResult(storedArray)
    console.log(":INFO: Hello from ResultsScreen.jsx: Stored Array:", storedArray);

      setIsLoading(false);
      }
  }, [searchResultsState.searchResults]);

  
  // Check if the token exists in localStorage
  if (!userInfo.token) {
    // Redirect to a login page or any other page as needed
    return <Navigate to="/login" />;
  }
  
  
  return (
    <div className='ResultScreenContainer'>
      <Row>
        <Col>
          <h1>Search results for {searchString}</h1>
          <b>Search is conducted among 11,000 repositories</b> 
          <br></br>

        </Col>
      </Row>

      {showSuccessAlert && (
        <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
          Saved successfully!
        </Alert>
      )}
      
      {isLoading ? <Loader /> : null}
      <ResearchTable search_res={searchResult} onAddClick={handleAddClick} />

    </div>
  );
}