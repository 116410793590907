import {
    DEVELOPER_LIST_REQUEST,
    DEVELOPER_LIST_SUCCESS,
    DEVELOPER_LIST_FAIL
} from '../constants/developerConstants'

// A "reducer" is simply a function that takes IN our current State and is going to take an action into account into this State.

export const developerListReducer = (state = { developers: [] }, action) => {
    switch(action.type){
        case DEVELOPER_LIST_REQUEST:
            return {loading: true, developers:[]}
 
        case DEVELOPER_LIST_SUCCESS:
            return {loading: false, developers: action.payload}
    
        case DEVELOPER_LIST_FAIL:
            console.log('Failed to fetch /developers on the backend')
            console.log("action.payload: ", action.payload)
            return {loading: false, error: action.payload}
        
        default:
            return state
        }
}