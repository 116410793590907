import './RegisterScreen.css'

import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import FormContainer from '../../components/FormContainer'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import Message from '../../components/Message'

import { register } from '../../actions/userActions'

function RegisterScreen({ location, history }) {
  
    const navigate                              = useNavigate()

    const [email, setEmail]                     = useState('')
    const [userType, setUserType]               = useState(false);
    const [password, setPassword]               = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage]                 = useState('')
    const [magicWord, setMagicWord]             = useState('')

    const dispatch                              = useDispatch()

    const userRegister                  = useSelector(state => state.userRegister)
    const { error, loading, userInfo }  = userRegister
  
  
    const handleChangeUserType = (e) => {
        // Convert the selected value to a boolean
        const value = e.target.value === 'creator' ? true : false;
        setUserType(value);
      };
    

    const { search }                    = useParams()
    const redirect                      = search?.split('=')[1] || '/'

    const submitHandler = (e) => {


        console.log(`:INFO: Registration submitted: email: ${email}`)
        console.log(`:INFO: Registration submitted: userType ${userType}`)
        console.log(`:INFO: Registration submitted: password ${password}`)
        console.log(`:INFO: Registration submitted: message ${message}`)
        console.log(`:INFO: Registration submitted: magicWord ${magicWord}`)

        e.preventDefault()

        if(password !== confirmPassword){
            setMessage('Passwords do not match')
        } else {
            dispatch(register(email, email, password, userType));
        }
      }

        useEffect(() => {
        if (userInfo) {
            navigate('/')
        }
        }, [userInfo, history]) 
        
    return (
        <div className='Form_login_main'>
            <FormContainer className="registration-form">
                <h1>Registration</h1>

                {message && <Message variant='danger'>{message}</Message>}
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader/>}

                <Form onSubmit={submitHandler}>

                    <Form.Group controlId = 'email' style={{paddingTop: '10px'}}>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                        required
                        type        = 'email'
                        placeholder = 'Enter Email'
                        value       = {email}
                        onInput     = {(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId='userTypeForm' style={{ paddingTop: '10px' }}>
                    <Form.Label>I am a:</Form.Label>
                    <Form.Select onChange={handleChangeUserType}>
                        <option value="creator">Project Creator</option>
                        <option value="explorer">Project Explorer</option>
                    </Form.Select>
                    </Form.Group>

                    <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder='Enter Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='passwordConfirm'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            required
                            type='password'
                            placeholder='Confirm Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='magicWord' style={{paddingTop: '10px'}}>
                        <Form.Label>Magic Word</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter Magic Word'
                            value={magicWord}
                            onChange={(e) => setMagicWord(e.target.value)}
                        />
                    </Form.Group>
   

                    <Button type        = 'submit' 
                            variant     = 'primary' 
                            className   = 'submit_login'>
                        Register
                    </Button>

                </Form>

                <Row className='py-3'>

                <Row  style={{paddingTop: '10px'}}>
                    <Col>
                    Have an account?{' '}
                    <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                        Sign In
                    </Link>
                    </Col>
                </Row>

                <Row style={{paddingTop: '10px'}}>
                    <Col>
                        project-based.io is currently in Beta mode and therefore only accessible to those who have the Magic Word.
                    </Col>
                </Row>

            </Row>
            </FormContainer>
        </div>

  )
}

export default RegisterScreen