import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { projectListReducer } from './reducers/projectReducers'
import { userLoginReducer, 
         userRegisterReducer, 
         userDetailsReducer, 
         userUpdateProfileReducer } from './reducers/userReducers'
import { developerListReducer } from './reducers/developerReducer'
import { rootReducer } from './reducers/searchReducers'

const reducer       =   combineReducers({
    projectList:        projectListReducer,
    developerList:      developerListReducer,
    userLogin:          userLoginReducer,
    userRegister:       userRegisterReducer,
    userDetails:        userDetailsReducer,
    userUpdateProfile:  userUpdateProfileReducer,
    searchResultList:   rootReducer
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null


const initialState          = {
    userLogin: { userInfo: userInfoFromStorage}
}


const middleware            = [thunk]

const store                 = createStore(reducer, initialState, 
    composeWithDevTools(applyMiddleware(...middleware)))


export default store