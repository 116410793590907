import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import './Select.css';
import { useDispatch } from 'react-redux';
import { listDevelopers } from '../../actions/developerActions';

function SelectLocation({ onCitySelect }) {
  const [selectedCity, setSelectedCity] = useState('');
  const dispatch = useDispatch();

  const handleCitySelect = (city) => {
    console.log(`handleCitySelect triggered: ${city}`);
    setSelectedCity(city);
    onCitySelect(city); // Call the callback function with the selected city
  };

  const handleSelectLocation = (location_prefix) => {
    console.log(`:INFO: Selected another location. Location prefix: ${location_prefix}`);
    dispatch(listDevelopers('', location_prefix));
  };

  console.log(":INFO: Hello from SelectLocation.jsx");
  
  const handleLocationChange = (e) => {
    const selectedLocation = e.target.value;
    handleCitySelect(selectedLocation); // Call the city select handler
    handleSelectLocation(selectedLocation); // Call the location select handler
  };

  return (
    <Form.Select 
      aria-label="Default select example" 
      className='form_select_ctry'
      onChange={handleLocationChange}>

      <option value="LU">Luxembourg</option>
      <option value="PARIS">France - Paris</option>
    
    </Form.Select>
  );
}

export default SelectLocation;
