import HorizontalListGroup from "./ArrInListGroup_tech_stack"

import ListGroup from 'react-bootstrap/ListGroup';
import './ProjectsScreen.css'


export default function ProjectsList(props) {

    console.log(`Hello from ProjectInListGroup.js. props: ${JSON.stringify(props)}`);
  
    return (
      <ListGroup variant="flush" className='list-group-projects'>
        {props.projects.map(project => (
          <ListGroup.Item key={project._id} className="ListItemProjects">
            <h4 className="h4_project_title">{project.project_title}</h4>
            <div className="ms-1 me-auto" style={{ whiteSpace: 'nowrap' }}>
              <div><b>Creator:</b> {project.creator_username}</div>
              <div><b>Description:</b> {project.description}</div>


              {project.website && (
                <div>
                  <b>Website:</b> <a href={project.website}>{project.website}</a>
                </div>
              )}

                <br></br>

              {project.repo && (
                <div>
                  <b>Repository:</b> <a href={project.repo}>{project.repo}</a>
                </div>
              )}


              {project.tech_field && project.tech_field.length > 0 && (
                <div>
                  <b>Business domain:</b> 
                  <HorizontalListGroup tech_stack={project.tech_field} />
                </div>
              )}
     

              {project.tech_stack && project.tech_stack.length > 0 && (
                <div>
                  <b>Tech stack:</b> 
                  <HorizontalListGroup tech_stack={project.tech_stack} />
                </div>
              )}

            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  }
  