import { Container } from 'react-bootstrap'
import './ProjectsScreen.css'
import { useEffect } from 'react';
import ProjectsList from './ProjectInListGroup';
import { useDispatch, useSelector } from 'react-redux'

import Loader from '../../components/Loader'
import Message from '../../components/Message'

import { listProjects } from '../../actions/projectActions'



function Projects() {

  console.log('Projects function called...');

  const dispatch = useDispatch();
  console.log('Dispatch function retrieved...');

  const projectList = useSelector(state => state.projectList);
  console.log('Project list retrieved from state...');

  const { error, loading, projects } = projectList;
  console.log('Project list data destructured...');

  useEffect(() => {
      console.log('useEffect called...');

      dispatch(listProjects());
      console.log('listProjects action dispatched...');

  }, [dispatch]);

  return (
      <div>
          <Container>
              <h1 className='h1_main_projects'>./Projects of the month</h1>
              {loading ? (
                  <Loader />
              ) : error ? (
                  <Message variant="danger">{error}</Message>
              ) : (
                  <ProjectsList projects={projects} />
              )}
          </Container>
      </div>
  );
}

export default Projects;
