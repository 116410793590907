import axios from 'axios'

import {  USER_LOGIN_REQUEST, 
          USER_LOGIN_SUCCESS,
          USER_LOGIN_FAIL,

          USER_LOGOUT,

          USER_REGISTER_FAIL,
          USER_REGISTER_SUCCESS,
          USER_REGISTER_REQUEST,

          USER_DETAILS_FAIL,
          USER_DETAILS_SUCCESS,
          USER_DETAILS_REQUEST,

          USER_UPDATE_PROFILE_REQUEST,
          USER_UPDATE_PROFILE_SUCCESS,
          USER_UPDATE_PROFILE_FAIL,

        } from '../constants/userConstants'


import { API_URL } from '../sharedVariable'


export const login = (email, password) => async (dispatch) => {
try{
  console.log('Login request initiated');

    dispatch({
        type: USER_LOGIN_REQUEST
    })

    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json, text/html',
        },
        parses: [
          'application/json',
          'application/x-www-form-urlencoded',
          'multipart/form-data'
        ],
        renders: [
          'application/json',
          'text/html'
        ],
      };

    console.log(`:INFO: Hello from userActions.js. Contacting: ${API_URL}api/user/token/`)
    const {data} = await axios.post(
      `${API_URL}api/user/token/`,
        { 'email': email, 'password': password},
        config
        )
      

    dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data
})

localStorage.setItem('userInfo', JSON.stringify(data))
localStorage.setItem('token', JSON.stringify(data.token))
console.log(`:INFO: User login successful ${JSON.stringify(data.token)}`);

}catch(error){

  console.error('User login failed');
  console.error('Error message:', error.response && error.response.data.detail
    ? error.response.data.detail
    : error.message);


    dispatch({
        type: USER_LOGIN_FAIL,
        payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
    })

}
}


export const userLoginSuccess = (data) => ({
  type: USER_LOGIN_SUCCESS,
  payload: data
});

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo')
  localStorage.removeItem('token')
  dispatch({type: USER_LOGOUT})
}



export const register = (email, username, password, userType) => async (dispatch) => {
  try{
      dispatch({
          type: USER_REGISTER_REQUEST
      })
  
      const config = {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json, text/html',
          },
          parses: [
            'application/json',
            'application/x-www-form-urlencoded',
            'multipart/form-data'
          ],
          renders: [
            'application/json',
            'text/html'
          ],
        };
        
        console.log(`Hello from register register ${API_URL}api/user/create/`);
        console.log('Parameters:', {
          email,
          username,
          password,
          userType
        });
        
        const {data} = await axios.post(
        `${API_URL}api/user/create/`,
          { 'email': email, 
            'name': username, 
            'password': password, 
            'is_creator': userType,
            'linkedin': ""
            },
          config
          )


          dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        
  localStorage.setItem('userInfo', JSON.stringify(data))
  localStorage.setItem('token', JSON.stringify(data.token))
  
  }catch(error){
      dispatch({
          type: USER_REGISTER_FAIL,
          payload: error.response && error.response.data.detail
              ? error.response.data.detail
              : error.message
      })
  
  }
  }

  export const getUserDetails = () => async (dispatch, getState) => {
    try{


        dispatch({
            type: USER_DETAILS_REQUEST
        })


        const {
          userLogin: { userInfo }
        } = getState()

        console.log(`:INFO: Hello from getUserDetails in userActions.js userInfo.token: ${userInfo.token}`)
    
        const config = {
            headers: {
              'Content-type': 'application/json',
              'Accept': 'application/json, text/html',
                Authorization: `Token ${userInfo.token}`.replace(/"/g, '')
            },
            parses: [
              'application/json',
              'application/x-www-form-urlencoded',
              'multipart/form-data'
            ],
            renders: [
              'application/json',
              'text/html'
            ],
          };

          console.log(`:INFO: Trying to get data from ${API_URL}api/user/me/ with Token ${userInfo.token.replace(/"/g, '')}`)

    
        const {data} = await axios.get(
            `${API_URL}api/user/me/`,
            config
            )

            console.log(':INFO: data :', JSON.stringify(data, null, 2));
    
        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
    })
    
    }catch(error){

        dispatch({
            type: USER_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
        console.log(error.message)
      }
    }



    // ... other imports and constants ...
    
    export const updateUserProfile = (updatedUser) => async (dispatch, getState) => {
      try {
        const {
          userLogin: { userInfo }
        } = getState();
    
        dispatch({ type: USER_UPDATE_PROFILE_REQUEST });
    
        console.log(`:INFO: updateUserProfile called: ${JSON.stringify(updatedUser)}`);
        console.log(`:INFO: updateUserProfile called, token: : ${userInfo.token}`);
    
        const url = `${API_URL}api/user/me/`;
        const config = {
          headers: {
            'Content-Type': 'application/json',  // Set Content-Type to application/json
            Authorization: `Token ${userInfo.token}`.replace(/"/g, '')
          }
        };
    
        console.log('Request Headers:', config.headers);
    
        const { data } = await axios.put(url, updatedUser, config);
    
        console.log(JSON.stringify(data));
    
        dispatch({
          type: USER_UPDATE_PROFILE_SUCCESS,
          payload: data
        });
      } catch (error) {
        dispatch({
          type: USER_UPDATE_PROFILE_FAIL,
          payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
        });
    
        console.log(error.message);
        console.log(error.response.data.detail);
      }
    };
    