import React from 'react';
import { MDBCol, 
         MDBContainer, 
         MDBRow, 
         MDBCard, 
         MDBCardText, 
         MDBCardBody, 
         MDBCardImage, 
         MDBTypography } from 'mdb-react-ui-kit';

         
import './CreatorScreen.css';
import AlexCover from '../../img/cover_alex.jpeg'
import AlexPic from '../../img/alex.jpg'

import pb_diagram from '../../img/project_based_data_flow.jpg'
import fp_diagram from '../../img/signal_flow_na2.png'
import dash from '../../img/dash.png'

export default function EditButton() {
  return (
    <div className="gradient-custom-2" style={{ backgroundColor: '#ffffff' }}>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="9" xl="9">

            <MDBCard>
              <div className = "rounded-top text-white d-flex flex-row" 
                   style     = {{ backgroundImage: `url(${AlexCover})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat', 
                                  height: '200px' }}>

                <div className="ms-4 mt-5 d-flex flex-column" style={{ width: '150px' }}>
                  <MDBCardImage src={AlexPic}
                    alt="Generic placeholder image" className="mt-4 mb-2 img-thumbnail" fluid style={{ width: '150px', zIndex: '1' }} />
                </div>
                <div className="ms-3" style={{ marginTop: '130px' }}>
                  <MDBTypography tag="h5">Alexandre Nesovic</MDBTypography>
                  <MDBCardText>Luxembourg, Luxembourg</MDBCardText>
                </div>
              </div>

              {
              <div className="p-4 text-black" style={{ backgroundColor: '#f8f9fa' }}>
                <div className="d-flex justify-content-end text-center py-1">
                  <div>
                    <MDBCardText className="mb-1 h5">4</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Documents</MDBCardText>
                  </div>
                  <div className="px-3">
                    <MDBCardText className="mb-1 h5">1026</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Followers</MDBCardText>
                  </div>
                  <div>
                    <MDBCardText className="mb-1 h5">478</MDBCardText>
                    <MDBCardText className="small text-muted mb-0">Following</MDBCardText>
                  </div>
                </div>
              </div>
              }
              <MDBCardBody className="text-black p-4">
                <div className="mb-5">
                  <p className="lead fw-normal mb-1">About (Creator)</p>
                  <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                    <MDBCardText className="font-italic mb-1">Sofware Developer, Founder of project-based.io</MDBCardText>
                    <MDBCardText className="font-italic mb-1">Lives in Luxembourg</MDBCardText>
                    <MDBCardText className="font-italic mb-0">Interested by the impacts of 
                    Information Infrastructures on collective consciousness</MDBCardText>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <MDBCardText className="lead fw-normal mb-0">Documents</MDBCardText>
                  <MDBCardText className="mb-0"><a href="#!" className="text-muted">Show all</a></MDBCardText>
                </div>

                <MDBRow>
                  <MDBCol className="mb-2">
                    <MDBCardImage src={pb_diagram}
                      alt="image 1" className="w-100 rounded-3" />
                  </MDBCol>
                  <MDBCol className="mb-2">
                    <MDBCardImage src={fp_diagram}
                      alt="image 1" className="w-100 rounded-3" />
                  </MDBCol>
                </MDBRow>

                <MDBRow>

                  <MDBCol className="mb-2">
                    <MDBCardImage src={dash}
                      alt="image 1" className="w-100 rounded-3" />
                  </MDBCol>
                </MDBRow>

              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}