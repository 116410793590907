import './LoginScreen.css'

import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import FormContainer from '../../components/FormContainer'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../actions/userActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'

function LoginScreen({ location, history }) {

  console.log('LoginScreen function called...');

  const navigate = useNavigate();
  console.log('Navigate function retrieved...');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin);

  const { error, loading, userInfo } = userLogin;

  const { search } = useParams();

  const redirect = search?.split('=')[1] || '/';
  console.log(`Redirect URL set to ${redirect}`);

  useEffect(() => {
    console.log('useEffect called...');
    if (userInfo) {
      console.log('User is logged in, navigating to /');
      navigate('/');
    }
  }, [userInfo, history]);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(`:INFO: [LoginScreen.js] Submitting login form: email: ${email}`);
    dispatch(login(email, password));
    console.log('Login action dispatched...');
  }

  return (
    <div className='Form_login_main'>
      <FormContainer>
        <h1>Sign in</h1>

        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader/>}

        <Form onSubmit={submitHandler}>
          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter Email'
              value={email}
              onInput={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId='password' className='pass_form'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button type='submit' variant='primary' className='submit_login'>
            Sign In
          </Button>
          <Row className='new_creator py-3'>
            <Col>
              New creator?{' '}
              <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                Register
              </Link>
            </Col>
          </Row>
        </Form>
      </FormContainer>
    </div>
  )
}

export default LoginScreen
