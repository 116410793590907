import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faFlask } from '@fortawesome/free-solid-svg-icons'; // Import the experiment-related icon
import pb_logo from '../assets/high_res_logo/project-basedio-high-resolution-logo-color-on-transparent-background.png';
import { logout } from '../actions/userActions';
import { useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { userLoginSuccess } from '../actions/userActions';

import './Header.css';

function Header() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userInfoFromStorage = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : null;

    if (userInfoFromStorage) {
      dispatch(userLoginSuccess(userInfoFromStorage));
    }
  }, [dispatch]);

  const logoutHandler = () => {
    console.log('Logout.');
    dispatch(logout());
    localStorage.removeItem('token');
    navigate('/');
  };

  const loggedIn = !!userInfo;

  const isMobile = window.innerWidth <= 768; // Set your desired breakpoint here

  return (
    <header>
      <Navbar bg="light" variant="light" className="custom-navbar">
        <Container className="container_header">
          <LinkContainer to="/">
            <Navbar.Brand href="#home project based">
              <img
                src={pb_logo}
                width="159"
                height="24"
                className="d-inline-block logo"
                alt="project-based.io logo"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Nav>
            {isMobile ? (
              <NavDropdown title={<FontAwesomeIcon icon={faBars} />} id="mobile-menu">
                {loggedIn && (
                  <LinkContainer to="/projects">
                    <NavDropdown.Item><FontAwesomeIcon icon={faFlask} /> Selected Projects</NavDropdown.Item>
                  </LinkContainer>
                )}
                {loggedIn && (
                  <NavDropdown.Item href="/saved-projects">Saved Projects</NavDropdown.Item>
                )}
                {loggedIn && <NavDropdown.Divider />}
                {loggedIn && <NavDropdown.Item href="/search_results">Search Results</NavDropdown.Item>}
                {loggedIn && <NavDropdown.Item href="/developers">Developers</NavDropdown.Item>}
                {userInfo ? (
                  <>
                    <NavDropdown.Divider />
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>User Information</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                  </>
                ) : (
                  <LinkContainer to="/login">
                    <NavDropdown.Item>Login</NavDropdown.Item>
                  </LinkContainer>
                )}
              </NavDropdown>
            ) : (
              <>
                {loggedIn && (
                  <NavDropdown title={<><FontAwesomeIcon icon={faFlask} /></>} id="projects-dropdown">
                    <LinkContainer to="/projects">
                      <NavDropdown.Item>Selected Projects</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item href="/saved-projects">Saved Projects</NavDropdown.Item>
                  </NavDropdown>
                )}
                {loggedIn && <Nav.Link href="/search_results">Search Results</Nav.Link>}
                {loggedIn && <Nav.Link href="/developers">Developers</Nav.Link>}
                {userInfo ? (
                  <NavDropdown title={<><FontAwesomeIcon icon={faUser} /> {userInfo.email}</>} id="username">
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>User Information</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <Nav.Link href="/login">Login</Nav.Link>
                )}
              </>
            )}
          </Nav>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;