import React, { useState } from 'react';
import './HomeScreen.css';
import { Form, InputGroup, Modal, Button } from 'react-bootstrap';
import COBE_GLOBE from '../../components/COBE_GLOBE';
import { markers } from './Markers';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchResults } from '../../actions/searchActions';
import { useNavigate } from 'react-router-dom';

function HomeScreen() {
  const [inputValue, setInputValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEmptySearchModal, setShowEmptySearchModal] = useState(false);
  const dispatch = useDispatch(); // Get the dispatch function from Redux
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const navigate = useNavigate(); // Get the navigate function from React Router

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };


  const handleEnter = () => {
    if (!userInfo) {
      setShowModal(true);
    } else if (inputValue.trim() === '') {
      setShowEmptySearchModal(true);
      return;
    } else {
      
      const counterKey = 'counterSearch';
      
      localStorage.setItem(counterKey, '1');
      localStorage.setItem("search_load", true);

      // TODO Re-init store and cache (res_arr)


      console.log("Hello from handleEnter...Removing res_arr")
      localStorage.removeItem("res_arr");
      localStorage.setItem("res_arr", '[{"owner": "Data", "repository": "Data", "description": "Data", "why": "Data"}]');

      localStorage.setItem(counterKey, '1');

  
      // Dispatch the action to trigger the API request
      dispatch(setSearchResults(inputValue, 1));
      const searchString = encodeURIComponent(inputValue); // Encode the string for safe URL usage
      navigate(`/search_results?searchString=${searchString}`);
    }
  };
    

  return (
    <>
      <div className="upper_page">
        <div className="left-rectangle">
          <div className="left-rectanglt-content">
            <p className="text_slogan">
              The <span className="tech_creator">technological project's</span> platform.
            </p>
            <p className="text_slogan_future">
              The future of technological enterprise resides in a <i>project-based</i> approach.
            </p>
            <div className="user-input-container">
              <InputGroup>
                <Form.Control
                  type="text"
                  value={inputValue}
                  onChange={handleChange}
                  className="user-input"
                  placeholder="Ex: 'web', 'frontend', 'protocols', 'data science',..."
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleEnter();
                    }
                  }}
                />
                  <Button variant="outline-secondary" onClick={handleEnter} className="search-button">
                    Search
                  </Button>

              </InputGroup>
              <div className="tick-line" />
            </div>
          </div>
        </div>
        <div className="right-rectangle">
          {<COBE_GLOBE markers={markers} />}
        </div>
      </div>

      {/* Modal component for empty search */}
      <Modal show={showEmptySearchModal} onHide={() => setShowEmptySearchModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Empty Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please enter a search term before performing the search.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEmptySearchModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal component for login */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Please Log In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You need to log in to perform this action.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HomeScreen;
