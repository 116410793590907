import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap'; // Make sure to include Row and Col

import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails, updateUserProfile } from '../../actions/userActions'; // Make sure to import the necessary actions
import "./ProfileScreen.css"

function ProfileScreen({ history }) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState(''); // Add state for name
  const [message, setMessage] = useState(''); // Add this line to declare the setMessage function

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);


  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      if (!success) {
        dispatch(getUserDetails('profile'));
      }
    }
  }, [dispatch, history, userInfo, success]);

const submitHandler = (e) => {
  e.preventDefault();

  if (password !== confirmPassword) {
    setMessage('Password do not match');
  } else {
    // Dispatch updateUserProfile action here
    dispatch(
      updateUserProfile({
        email,
        name,
        password,
      })
    );
  }
};


  return (
    <div className="Profile_main gradient-custom-2" style={{ backgroundColor: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Row>

      <h1>Hello {userDetails.user.name}</h1>
      <div className="Profile_main gradient-custom-2" style={{ backgroundColor: '#ffffff' }}>
        <Form
          className="rounded p-4 shadow"
          style={{ maxWidth: '400px' }}
          onSubmit={submitHandler} // Call the submitHandler when the form is submitted
        >
          <h3 className="mb-4">Update User Information</h3>
          <Container>



            <Form.Group controlId="formUsername">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

              <Form.Group controlId="formName">
              <Row>
                    <Form.Label className='username_field'>Username</Form.Label>
                    <Form.Text className="text-muted spec_chars">Spaces and special characters are not allowed.</Form.Text>
                </Row>
                <Form.Control
                  type="text"
                  placeholder="Enter Username"
                  value={name}
                  onChange={(e) => {
                    // Remove spaces and special characters from the input
                    const sanitizedInput = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                    setName(sanitizedInput);
                  }}
                />
              </Form.Group>


            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordTouched(true); // Mark confirm password as touched
                  }}
                  style={{
                    borderColor: confirmPasswordTouched && password !== confirmPassword ? 'red' : (confirmPasswordTouched && password === confirmPassword ? 'green' : ''),
                  }}
                />
                {confirmPasswordTouched && password !== confirmPassword && (
                  <small className="text-danger">Passwords do not match</small>
                )}
              </Form.Group>

            <Button
            variant="primary"
            type="submit"
            className="submit_btn"
            disabled={password !== confirmPassword}
            >
            Update
            </Button>


          </Container>
        </Form>
      </div>
    </Row>
    </div>


  );
}

export default ProfileScreen;
