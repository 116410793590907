import ListGroup from 'react-bootstrap/ListGroup';

export default function HorizontalListGroup({tech_stack}) {

  let items = [];

  if (tech_stack && tech_stack.length > 0) {
    // string to arr conv
    const arr = JSON.parse(tech_stack.replace(/'/g, "\""));
    items = arr;
  }

  return (
    <ListGroup horizontal>
      {items.map((item, index) => (
        <ListGroup.Item key={index}>{item}</ListGroup.Item>
      ))}
    </ListGroup>
  );
}
