import React, { useEffect, useState } from 'react';
import './SavedProjectsScreen.css';
import StarsTable from '../../components/Saved_Projects_components/saved_tbl_comp';
import { API_URL } from '../../sharedVariable';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../actions/userActions'; // Make sure to import the necessary actions

import Loader from '../../components/Loader'

function SavedProjectsScreen({ history }) {
  const dispatch              = useDispatch();
  const [stars, setStars]     = useState([]);

  const userLogin             = useSelector((state) => state.userLogin);
  const { userInfo }          = userLogin;

  const userDetails           = useSelector((state) => state.userDetails);
  const [loading, setLoading] = useState(true); // State for tracking loading status



  console.log(`userDetails : ${JSON.stringify(userDetails.user)}`)
  console.log(`userInfo : ${userInfo}`)

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
        dispatch(getUserDetails('profile'));
    }
  }, [dispatch, history, userInfo]);


  useEffect(() => {
    async function fetchStars() {
      try {
        
        const config = {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Token ${userInfo.token}`
          }
        };

        console.log(`:INFO: Fetching: ${API_URL}api/project/get_selected_stars/`)
        console.log(`:INFO: Token: ${userInfo.token}`)
        
        let url = `${API_URL}api/project/get_selected_stars/`
        
        const response = await axios.get(url, config);; // Replace with your actual API endpoint  

        console.log(`:INFO: response: ${JSON.stringify(response.data)}`)
        console.log(`OK? ${response.status}`)

        if (response.status === 200) {
          
          const starsData = Object.keys(response.data.id).map((key) => ({
            id: response.data.id[key],
            owner: response.data.owner[key],
            repository: response.data.repository[key],
            description: response.data.description[key],
            relation: response.data.Why[key],
            user_id: response.data.user_id[key]
          }));
        
          setStars(starsData);
          setLoading(false); // Data has been fetched, set loading to false


        } else {
          console.error('Failed to fetch stars data');
        }
      } catch (error) {
        console.error('Error fetching stars data:', error);
        setLoading(false); // Error occurred, set loading to false

      }
    }

    fetchStars();
  }, []);


  return (
    <div className='SavedScreenContainer'>
      <h1>Saved Projects</h1>
      {loading ? ( // Display the Loader component while loading is true
        <Loader />
      ) : stars.length > 0 ? (
        <StarsTable stars={stars} />
      ) : (
        <p className='center_no_project'>
          No saved projects yet Dear <b>{userDetails.user.name}</b>
        </p>
      )}
    </div>
  );
}

export default SavedProjectsScreen;
