import axios from 'axios';
import { API_URL } from '../sharedVariable';

export const SEARCH_RESULTS_REQUEST = 'SEARCH_RESULTS_REQUEST';
export const SEARCH_RESULTS_SUCCESS = 'SEARCH_RESULTS_SUCCESS';
export const SEARCH_RESULTS_FAIL = 'SEARCH_RESULTS_FAIL';

export const setSearchResults = (inputValue, iteration) => async (dispatch, getState) => {
  try {
    const userLogin = getState().userLogin;
    const { userInfo } = userLogin;

    if (!userInfo) {
      dispatch({ type: SEARCH_RESULTS_FAIL, payload: "User not logged in." });
      return;
    }

    if (inputValue.trim() === '') {
      dispatch({ type: SEARCH_RESULTS_FAIL, payload: "Empty search term." });
      return;
    }

    dispatch({ type: SEARCH_RESULTS_REQUEST }); // Dispatch request action here

    const config = {
      headers: {
        Authorization: `Token ${userInfo.token}`,
      },
    };

    let url = `${API_URL}api/project/search_results/`;

    let searchDataCombined = []; // Array to hold combined searchData

    for (let i = 1; i <= 3; i++) {
      const response = await axios.post(
        url,
        { "relation": inputValue, "iter": i },
        config
      );

      let searchData = response.data.res;
      searchData = JSON.parse(searchData);
      console.log("searchData:", searchData);
      console.log("IterOk:", response.data.iter);

      searchDataCombined.push(...searchData);

      console.log("======> searchDataCombined: ", searchDataCombined)

      // Caching ("search_load") variable will be "ON" (true) untill it reaches END
      // when end iteration, variable will be "OFF", meaning loading ENDED.

      dispatch({
        type: SEARCH_RESULTS_SUCCESS,
        payload: searchDataCombined,
      });
    }

    localStorage.setItem("search_load", false);

  } catch (error) {
    dispatch({
      type: SEARCH_RESULTS_FAIL,
      payload: error.message || "Error fetching data",
    });
  }
};
