import Table from 'react-bootstrap/Table';
import { useState } from 'react';
import { MdClose } from 'react-icons/md'; // Import the cross icon
import './saved_tbl_comp.css';

function StarsTable({ stars, isBlurred }) {
  const [blurFromRow, setBlurFromRow] = useState(3);

  console.log("stars from StarsTable: ", stars)

  const handleDelete = (index) => {
    // Implement the delete logic here
    // You can use the index to identify the row to be deleted
    // Update your data array (stars) to remove the selected row
  };

  return (
    <Table striped bordered hover variant="dark" className='table_dev' style={{ borderRadius: '10px', overflow: 'hidden' }}>
      <thead>
        <tr>
          <th>Owner</th>
          <th>Repository</th>
          <th>Description</th>
          <th>Relation</th>
          <th>Delete</th> {/* New column header */}
        </tr>
      </thead>
      <tbody>
        {stars.map((row, index) => (
          <tr key={index} className={index >= blurFromRow && isBlurred ? 'blurred' : ''}>
            <td>{row.owner}</td>
            <td>{row.repository}</td>
            <td>{row.description}</td>
            <td>{row.relation}</td>
            <td>
              <div className="delete-container">
                <button className="delete-btn" onClick={() => handleDelete(index)}>
                  <MdClose className="delete-icon" />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default StarsTable;
