import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'

import './Footer.css';

function Footer() {
  return (
 <footer>
  <Container>
      <Row>
          <Col className="text-center py-3 foot_pb">Copyright &copy; 2023 project-based.io. All rights reserved.</Col>
      </Row>
      <Col className="text-center py-3 foot_pb"><b>Contact:</b> hello@project-based.io</Col>
      <Row>
      </Row>

  </Container>
</footer>
  
  )
}



export default Footer
