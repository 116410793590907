// longitude latitude
const DOT_SIZE = 0.06


export const markers = [
// USA
{ location: [37.7595, -122.4367], size: DOT_SIZE }, // San Francisco
{ location: [40.7128, -74.006], size: DOT_SIZE },   // New York
{ location: [37.4419, -122.1430], size: DOT_SIZE }, // Palo Alto
{ location: [37.7749, -122.4194], size: DOT_SIZE }, // San Francisco
{ location: [42.3601, -71.0589], size: DOT_SIZE },  // Boston
{ location: [30.2672, -97.7431], size: DOT_SIZE },  // Austin

// UK
{ location: [51.5074, -0.1278], size: DOT_SIZE },   // London

//Canada
{ location: [43.6532, -79.3832], size: DOT_SIZE },  // Toronto
{ location: [43.4509, -80.4842], size: DOT_SIZE },  // Kitchener

{ location: [51.1657, 10.4515], size: DOT_SIZE },   // Germany
{ location: [52.5200, 13.4049], size: DOT_SIZE },   // Berlin
{ location: [49.8153, 6.1296], size: DOT_SIZE },    // Luxembourg
{ location: [45.9432, 24.9668], size: DOT_SIZE },    // Romania

{ location: [18.5204, 73.8567], size: DOT_SIZE },   // Mumbai

{ location: [4.1755, 73.5093], size: DOT_SIZE },    // Maldives

{ location: [48.8566, 2.3522], size: DOT_SIZE },    // Paris
{ location: [35.8989, 14.5146], size: DOT_SIZE },   // Malta

// TODO
// South Africa
{ location: [-26.2041, 28.0473], size: DOT_SIZE }, // Johannesburg
{ location: [-33.9249, 18.4241], size: DOT_SIZE }, // Cape Town:
{ location: [-29.8587, 31.0292], size: DOT_SIZE }, // Durban
{ location: [-25.7461, 28.2294], size: DOT_SIZE }, // Pretoria
{ location: [-33.9608, 25.5701], size: DOT_SIZE }, // Port Elizabeth

// Nigeria
{ location: [6.5244, 3.3792], size: DOT_SIZE },   // Lagos
{ location: [9.0765, 7.5333], size: DOT_SIZE },   // Abuja:
{ location: [4.8156, 7.0134], size: DOT_SIZE },   // Port Harcourt
{ location: [12.0024, 8.5073], size: DOT_SIZE },  // Kano
{ location: [6.4418, 7.4981], size: DOT_SIZE },   // Enugu

//Egypt
{ location: [30.0444, 31.2357], size: DOT_SIZE }, // Cairo
{ location: [31.2293, 29.9511], size: DOT_SIZE }, // Alexandria
{ location: [30.0131, 31.2058], size: DOT_SIZE }, // Giza
{ location: [25.6872, 32.6528], size: DOT_SIZE }, // Luxor
{ location: [24.0889, 32.8797], size: DOT_SIZE }, // Aswan
]