import axios from 'axios';
import {
  DEVELOPER_LIST_REQUEST,
  DEVELOPER_LIST_SUCCESS,
  DEVELOPER_LIST_FAIL
} from '../constants/developerConstants';
import { API_URL } from '../sharedVariable';

export const listDevelopers = (keyword = '', location_prefix = '') => async (dispatch, getState) => {
  const { userLogin: { userInfo } } = getState();

  console.log(`:INFO: Hello from listDevelopers. Location prefix fetch from SelectionLocation.jsx: ${location_prefix}`)
  try {
    dispatch({ type: DEVELOPER_LIST_REQUEST });

    console.log(`:INFO: Fetching: ${API_URL}api/developers/${location_prefix}`)
    let url = `${API_URL}api/developers/${location_prefix}`;
    

    console.log(`Hello from developerActions.js calling: ${url}`)

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Token ${userInfo.token}`
      }
    };
  
    const response = await axios.get(url, config);
    console.log("response:", response)
  
    dispatch({
      type: DEVELOPER_LIST_SUCCESS,
      payload: response.data
    });
  
  } catch (error) {
    dispatch({
      type: DEVELOPER_LIST_FAIL,
      payload: error.response
    });
  }
};
