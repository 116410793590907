import React from 'react';
import './Error.css'


const Error401Screen = () => {
  return (
    <div className='error-screen'>
      <h1>401 Unauthorized</h1>
      <p>You are not authorized to access this page. Please log in or contact the administrator.</p>
    </div>
  );
};

export default Error401Screen;