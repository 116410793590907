import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/Home/HomeScreen';
import ProjectsScreen from './screens/Projects/ProjectsScreen';
import CreatorScreen from './screens/Creator/CreatorScreen';
import LoginScreen from "./screens/Login/LoginScreen";
import RegisterScreen from "./screens/Register/RegisterScreen";
import ProfileScreen from "./screens/Profile/ProfileScreen";
import DevelopersScreen from "./screens/Developers/DevelopersScreen";
import Error401Screen from "./screens/ErrorScreens/Error401Screen";
import ResultsScreen from "./screens/Results/ResultsScreen"
import SavedProjectsScreen from './screens/SavedProjects/SavedProjectsScreen';

import { API_URL } from './sharedVariable';

console.log(`App.js called. API URL used: ${API_URL}`);

function App() {
  return (
    <Router>
      <>
        <Header />
        <main className='py-3'>
          <Routes>
            <Route path='/' element={<HomeScreen />} />
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/register' element={<RegisterScreen />} />
            <Route path='/creator' element={<CreatorScreen />} />
            <Route path='/developers' element={<DevelopersScreen />} />
            <Route path='/search_results' element={<ResultsScreen />} />
            <Route path='/profile' element={<ProfileScreen />}/>
            <Route path='/projects' element={<ProjectsScreen />} />
            <Route path='/Error401Screen' element={<Error401Screen />} />
            <Route path='/saved-projects' element={<SavedProjectsScreen />} />
          </Routes>
        </main>
        <Footer />
      </>
    </Router>
  );
}

export default App;
