import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { FaPlus } from 'react-icons/fa';
import './research_tbl_comp.css'
import { setSearchResults } from '../../actions/searchActions';
import { useDispatch, useSelector } from 'react-redux';

function ResearchTable({ onAddClick }) {
  const [rowCount, setRowCount] = useState(0);
  const dispatch = useDispatch(); // Get the dispatch function from Redux
  const searchResultsState = useSelector(state => state.searchResultList);

  console.log("====>:INFO: Hello from stars_tbl_comp: Hello from searchResultsState", searchResultsState.searchResults)

  const handleAddNewData = () => {
    setRowCount(rowCount + 1);
    dispatch(setSearchResults('test', 1));
  };

  return (
    <div>
      <Table striped bordered hover variant="dark" className="table_dev" style={{ borderRadius: '10px', overflow: 'hidden' }}>
        <thead>
          <tr>
            <th>Owner</th>
            <th>Repository</th>
            <th>Description</th>
            <th>Relation</th>
            <th>Save</th>
          </tr>
        </thead>
        <tbody>
          {searchResultsState.searchResults.map((row, index) => (
            <tr key={index}>
              <td>{row.owner}</td>
              <td>{row.repository}</td>
              <td>{row.description}</td>
              <td>{row.why}</td>
              <td className="add-cell">
                <FaPlus className="add-icon" onClick={() => onAddClick(row)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <button onClick={handleAddNewData}>New Research</button>
    </div>
  );
}

export default ResearchTable;
