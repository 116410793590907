import React from 'react'
import DevelopersTable from '../../components/Developers_components/Dev';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react';
import './DevelopersScreen.css'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Loader from '../../components/Loader'
import Message from '../../components/Message'

import { listDevelopers } from '../../actions/developerActions'
import SelectLocation  from '../../components/Developers_components/SelectLocation'
import { Navigate } from 'react-router-dom';
import unlock from '../../assets/svg/unlock.svg'
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

export default function DevelopersScreen() {
  const dispatch = useDispatch();
  const developersList = useSelector(state => state.developerList);

  const [nDevelopers, setNdevelopers] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const { error, loading, developers } = developersList;
  const [isBlurred, setIsBlurred] = useState(true);

  let cities_mapping = {
    "LU": "Luxembourg",
    "PARIS": "Paris"
  }

  if (selectedCity.length === 0) {
    setSelectedCity("Luxembourg");
  }
  else if (Object.keys(cities_mapping).includes(selectedCity)){
    console.log(":INFO: ==> Includes ! ")
    setSelectedCity(cities_mapping[selectedCity])
  }


  const convertToCSV = (data) => {
    const csv = Papa.unparse(data, {
      header: true,  // Include headers in the CSV
    });
    return csv;
  };


  const handleDownloadCSV = () => {

    console.log(`handleDownloadCSV called.`)
    // Convert developers data to CSV format
    const csvData = convertToCSV(developers);

    // Create a Blob object from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

    // Use FileSaver.js to prompt user to download the file
    saveAs(blob, `developers_${selectedCity}.csv`);
  };


  useEffect(() => {
    // Update the count of developers when 'developers' change
    setNdevelopers(developers.length);
  }, [developers]); // Run the effect whenever 'developers' change


  const handleToggleBlur = () => {
    setIsBlurred(!isBlurred);
  };

  const handleUnlock = () => {
    // Your unlock logic goes here
  };

  useEffect(() => {
    dispatch(listDevelopers('', ''));
  }, [dispatch]);

  // Check if the token exists in localStorage
  const token = localStorage.getItem('token');
  if (!token) {
    // Redirect to a login page or any other page as needed
    return <Navigate to="/login" />;
  }

  if (error) {
    return <p>Error from the API</p>;
  }
  

  const handleCitySelection = (city) => {
    setSelectedCity(city);
  };

  return (
      <div className='DevScreenContainer'>
        <Row>
          <Col>
            <h1>Developers </h1>
            <b>{nDevelopers} developers detected in {selectedCity}</b>
            
          </Col>

          <Col >
            <h4>Last Update:</h4>Aug 11
          </Col>

          <Col xs="auto" >
            <SelectLocation onCitySelect={handleCitySelection} />
          </Col>

          <Col xs="auto" className="text-right">
            <img width="20" 
                className='unlock' 
                src={unlock} 
                alt="unlock" 
                onClick={handleUnlock}
                />

              <Button variant="dark" onClick={handleToggleBlur} className="ml-auto">
                {isBlurred ? 'Unblur' : 'Blur'}
              </Button>
          </Col>

          <Col xs="auto" className="text-right">
            <Button variant="dark" className="ml-auto" onClick={handleDownloadCSV}>
              Download as CSV
            </Button>
          </Col>

        </Row>

        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader/>}

        {(developers) && <DevelopersTable developers={developers} isBlurred={isBlurred} />}

        </div>
  )
}
